<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:headerAction>
                <router-link to="trainer-wise-evaluation" :class="'btn btn-success text-light mr-2'">{{ $t('elearning_tim.trainer_wise_evaluation') }} {{ $t('globalTrans.list') }}</router-link>
                <!-- <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.export_pdf') }}
                </b-button> -->
            </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <tbody>
                    <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ formData.circular_memo_no }}</td>
                      <th style="width: 15%" class="text-right" >{{ $t('elearning_config.fiscal_year') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $t('elearning_config.organization') }}</th>
                      <th style="width: 5%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">:</th>
                      <td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.office_type') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.office') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_type') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_category') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_title') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('externalTraining.trainer_name') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.trainer_name_bn : formData.trainer_name }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_tim.trainee_name') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.trainee_name_bn : formData.trainee_name }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
                <b-row>
                  <div class="col-md-12" id="form">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.evaluation_list')}}</legend>
                      <table class="table" style="width:100%">
                        <thead class="thead">
                          <tr>
                            <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                            <th>{{$t('elearning_config.trainer_evaluation_name')}}</th>
                            <th>{{$t('elearning_config.evaluation_marks')}}</th>
                            <th>{{$t('externalTraining.attain_marks')}}</th>
                          </tr>
                        </thead>
                        <tr v-for="(module_trainer,index) in formData.trainer_evaluation_marks" :key="index">
                          <td>
                            {{$n(index+1)}}
                          </td>
                          <td >
                            {{ module_trainer.evaluation_name }}
                          </td>
                          <td >
                            {{ module_trainer.evaluation_marks }}
                          </td>
                          <td >
                            {{ module_trainer.attain_marks }}
                          </td>
                        </tr>
                      </table>
                    </fieldset>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'

export default {
//   props: ['id'],
  created () {
    if (this.$route.query.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
